.dashboard--layout{
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  .dashboard--widget {
    display: flex;
    flex-direction: column;
    width: 100%;
    .widget--title {
      font-size: 2em;
      font-weight: 600;
    }

    & + .dashboard--widget {
      margin-top: 40px;
    }
  }
}