.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  .form__layout-add{
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 12px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }
  .form__layout-edit {
    display: flex;
    flex-direction: row;
    width: 100%;
    .form_container {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #FFF;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      padding: 12px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
      margin-right: 12px;
    }
    .form__options--container {
      display: flex;
      flex-direction: column;
      min-width: 320px;
      max-width: 320px;
      background-color: #FFF;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
      padding: 12px;
      .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        > div {
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          > * {
            & + * {
              margin-left: 12px;
            }
          }
        }
        > button {
          margin-bottom: 20px;
          width: auto;
        }
      }
      .options--row {
        margin-top: 12px;
      }
    }
  }

  .coords--wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > div {
      padding-left: 12px;
    }
  }

  .map {
    width: 100%;
    min-height: 420px;
  }


}
