@import "list";
@import "item";


.widget{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    & + .widget {
        margin-top: 20px;
    }
    .widget--title {
        font-size: 1.3rem;
        font-weight: 800;
    }
    .widget--content {
        background-color: #FFF;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
        width: 100%;
    }
}