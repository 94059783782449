.insert--image_plg {
  display: flex;
  flex-direction: column;
  width: 100%;
  .actions {
    margin-bottom: 12px;
  }
}
.media--list {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #dfdfdf;
  border-radius: 4px;
  padding: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  .media--list_item {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    min-width: 240px;
    width: 100%;
    background-color: #FFF;
    margin-bottom: 20px;

    &:nth-child(5n) {
      margin-left: 0;
    }
    .cover--background {
      background-repeat: no-repeat;
      background-position: center center;
      height: 160px;
      background-size: cover;
      video {
        height: 100%;
        width: auto;
      }
      iframe {
        width: 100%;
        height: auto;
      }
    }
    .image--info {
      padding: 8px;
    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}


.media--selected_list {
  display: flex;
  flex-direction: column;
  width: 100%;
  .media--selected_item {
    display: flex;
    flex-direction: row;
    border: 1px solid #dfdfdf;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 4px;
    &.type_video {
      .cover {
        min-width: 240px;
        max-width: 240px;
      }
    }

    &.type_externalvideo {
      .cover {
        min-width: 240px;
        max-width: 240px;
      }
    }

    & + .media--selected_item {
      margin-top: 12px;
    }
    .cover {
      display: inline-block;
      flex-direction: column;
      min-width: 160px;
      max-width: 160px;
      height: 160px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 10px;
      video {
        height: auto;
        width: 100%;
      }
      iframe {
        height: auto;
        width: 100%;
      }
      //border-radius: 160px;
    }
    .info {
      flex: 1;
      flex-direction: column;
      padding-right: 20px;
    }
    .actions {
      display: flex;
      flex-direction: column;
      width: 32px;
      align-items: center;
      justify-content: center;
      > button {
        & + button {
          margin-top: 10px;
        }
      }

    }
  }
}
