.homeworks {
  margin: 12px;
  background-color: #FFF;
}

.homework--layout {
  margin: 12px;

  .homework--widget {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFF;
    border-radius: 4px;


    .widget--title {
      font-weight: 600;
      border-bottom: 1px solid #dfdfdf;
      font-size: 16px;
      padding: 4px 12px;
    }

    .widget--content {
      padding: 4px 12px;
    }
  }
}

.homework--widget.homework--profile {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 120px;

  .profile__avatar {
    width: 120px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
  }

  .profile__info {
    flex-direction: column;
    display: flex;
    flex: 1;

    > div {
      display: flex;
      flex-direction: row;

      .label {
        margin-right: 12px;
        font-weight: 600;
      }
    }

    .profile__actions {
      margin-top: 20px;
    }
  }
}

.widget__info {
  .content--column {
    & + .content--column {
      border-top: 1px solid #dfdfdf;
    }

    .label {
      font-weight: bold;
    }
  }
}

.widget__criteria {
  .form {
    margin-top: 10px;

    .form--row {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;

      & + .form--row {
        margin-top: 10px;
      }

      .label {
        margin-right: 20px;
      }
    }
  }
}




.files--list {
  background-color: #FFF;
  display: block;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 4px;
  .file--item {
    display: flex;
    flex-direction: row;
    .ant-spin-nested-loading {
      width: 100%;
    }
    .file--wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    .title {
      flex: 1;
      padding: 0 4px;
    }
    .actions {
      padding: 0 4px;
    }
    & + .file--item {
      border-top: 1px solid #dfdfdf;
    }
  }
}