.submits--list {
    display: grid;
    width: 100%;
    grid-gap: 24px;
    padding: 12px;
}

.course__submits--item {
    background-color: #FFF;
    padding: 12px;
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        width: 8px;
        height: "";
        display: block;
        position: absolute;
        background-color: #77BC1F;
        top: 0;
        left: 0;
        height: 100%;
    }

    .info--wrapper {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}