#root {
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
    &.app_loading{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.app_header {
  padding: 0;
}

.container {
  width: 100%;
  &.container__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
  }
}

.app_loading_nflex{
  width: 100%;
  height: 100%;
}

.app_view {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.profile__avatar {
  width: 160px;
  height: 160px;
  -webkit-border-radius: 160px;
  -moz-border-radius: 160px;
  border-radius: 160px;
  background-repeat: no-repeat;
  background-size: cover;
}
